.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
}

.active {
  opacity: 1;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.finish {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  border: none;
  border-radius: 50%;
  display: inline-block;
  /* opacity: 0.5; */
  background-color: #1bc5bd;
}
