// report
@import "./Styles//_shimmerLoader.scss";
@import "./Styles/_singleLineLoaders.scss";
@import "./Styles/__loaderUtils.scss";
@import "./Styles/_reportsTypeCards.scss";
@import "./Styles/_restaurantKot.scss";
@import "./Styles/_menuItems.scss";
@import "./Styles/_allShopCards.scss";
@import "./Styles/report.scss";
@import "./Styles/reportType.scss";
@import "./Styles/variable.scss";
@import "./Styles/_agGrid";
@import "./Styles/_flashManagerReport";
@import "./Styles/_auditReport";
@import "./Styles/_shops";
@import "./Styles/_addShops";
@import "./Styles/_shopTemplate";
@import "./Styles/_shopConfiguration";
@import "./Styles/addproductsTemplate";
@import "./Styles/_startOrder";
@import "./Styles/_startOrderTemplate";
@import "./Styles/_shopConfigurationAlternate";
@import "./Styles/_addToCart";
@import "./Styles/_houseKeeping";
@import "./Styles/_houseKeepingGrid";
@import "./Styles/_houseKeepingRooms";
@import "./Styles/_staffAssignment";
@import "./Styles/_houseKeepingCharter";
@import "./Styles/_houseKeepingCharterInsights";
@import "./Styles/_houseKeepingCharterTable";
@import "./Styles/_staffAssignmentTable";
@import "./Styles/_cartInvoice";
@import "./Styles/_houseKeepingRoomsUpdated";
@import "./Styles/_outOfOrderReport";
@import "./Styles/_folios";
@import "./Styles/__invoiceSummary";
@import "./Styles/_clickAwayListenerHelper";
@import "./Styles/_invoiceWrapper";
@import "./Styles/_invoiceSummaryTable";
@import "./Styles/_customerInformation";
@import "./Styles/_bookingInformation";
@import "./Styles/_modifyDates";
@import "./Styles/_upgradeRoom";
@import "./Styles/_splitRoom";
@import "./Styles/_advancedPortfolio";
@import "./Styles/_advPortfolioSummary";
@import "./Styles/_addServiceModal";
@import "./Styles/_editCustomerModal";
@import "./Styles/_userProfileDropdown";
@import "./Styles/_propertyDetails";
@import "./Styles/_editHotelDetails";
@import "./Styles/_settleDues";
@import "./Styles/_configureStaff";
@import "./Styles/_configfureTable";
@import "./Styles/_posViewOrder";
@import "./Styles/_createdOrder";
@import "./Styles/_roomTypeCard";
@import "./Styles/_editShop";
@import "./Styles/_addRoomType";
@import "./Styles/_dashboard";
@import "./Styles/_informationCard";
@import "./Styles/_quickPanel";
@import "./Styles/_buttonHelper";
@import "./Styles/__modalHelper";
@import "./Styles/__mockLoaderTable.scss";
@import "./Styles/_customLoader";
@import "./Styles/_viewBooking";
@import "./Styles/__enquiryValidationPage.scss";
@import "./Styles/_Isha.scss";
@import "./Styles/_menu.scss";
@import "./Styles/_rms.scss";
@import "./Styles/_addCardPayment.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// @import "~ag-grid-community/src/styles/ag-grid.scss";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
// .fc-highlight {
//   background:linear-gradient(#1b95ac 0%,#1bb195 100%) !important;
// }

body,
html {
  font-family: var(--mavenpro) !important;
  background-color: white;
}

.calendar .calendar-header {
  background-color: $primary;
  border: 0 !important;
}

.calendar .calendar-header .year-title {
  font-size: 18px !important;
  color: white;
}

.calendar
  .calendar-header
  .year-title:not(.year-neighbor):not(.year-neighbor2) {
  border-bottom: 2px solid $primary;
}

.calendar .months-container {
  padding: inherit;
}
.calendar .months-container .month-container {
  height: 260px !important;
  margin-bottom: 25px !important;
}

.calendar table.month {
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  height: 100% !important;
}

.calendar table.month th.month-title {
  background-color: $primary;
  color: white;
  padding: 12px !important;
  font-weight: 400 !important;
}

.calendar table.month th.day-header {
  padding-top: 10px !important;
  color: #cdcdcd;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.calendar table.month td.day .day-content {
  padding: 8px !important;
  border-radius: 100% !important;
}

.inventory-daterange-custom {
  .rdrCalendarWrapper {
    width: 100%;
  }
  // .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  //   border:  1px solid $primary;
  // }
  .rdrDateDisplayWrapper {
    display: none;
  }
  // .rdrDays{
  //   .rdrDay .rdrDayHovered{
  //     color: $primary;
  //     border-color: $primary;
  //   }
  // }
  // .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  //   border: 0px solid $primary;
  // }
  // .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  //   background: $primary;
  // }
  // .rdrDateDisplayItemActive{
  //   border-color: $primary;
  // }
  // .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  //   color: $primary;
  // }
  .rdrMonths {
    .rdrMonth {
      width: auto;
    }
  }
}

.custom-header {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  z-index: 90;
}

.edit-row {
  border-top-width: thick !important;
  border-top-color: $primary !important;
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}

.warning-row {
  border-top-width: thick !important;
  border-top-color: $warning !important;
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}
.rateplan-row {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}

.header-table {
  table {
    table-layout: fixed;
    margin-bottom: 0px;
  }
  th {
    background: #f5f5f6;
  }
}
.new-tab {
  overflow-x: visible !important;
  table {
    margin-bottom: 0px;
    table-layout: fixed;
    tbody {
      tr {
        td {
          overflow: auto;
        }
      }
    }
  }

  .calendar {
    .months-container {
      .months-container {
        margin-bottom: 25px;
      }
    }
  }

  .row-expansion-style {
    padding: 0px !important;
  }

  .table-bordered {
    border-bottom-width: thick;
  }
  // tbody{
  // color: $primary;
  // }
}

.open-close {
  background: #1b95ac;
  cursor: pointer;
}

.open-close-disabled {
  background: #2c3e50;
  cursor: pointer;
}

.sticky-header {
  table {
    white-space: nowrap;
    border-collapse: separate;
    table-layout: fixed;
  }

  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  table thead th:first-child {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  table tbody th {
    position: relative;
  }
}
.spe-height {
  height: 31px !important;
  overflow: hidden;
}
.special-display {
  height: 35px;
  border: 1px solid var(--fc-border-color, #ddd);
}
.dont-display {
  display: none !important;
}
.fc-timeline-event:not(.fc-event-start):before,
.fc-timeline-event:not(.fc-event-end):after {
  content: none;
}

// 3 dots loading css
/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */

.loader-new > div {
  z-index: 1000;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: $primary;
  opacity: 1;
}

.dimming-loader > div {
  animation: dimming-loader 0.6s infinite alternate;
}

@keyframes dimming-loader {
  to {
    opacity: 0.25;
  }
}

.dimming-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.dimming-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

// .spinner {
//   z-index: 10;
//   text-align: center;
// }

// .spinner > div {
//   width: 18px;
//   height: 18px;
//   background-color: #333;

//   border-radius: 100%;
//   display: inline-block;
//   -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
//   animation: sk-bouncedelay 1.4s infinite ease-in-out both;
// }

// .spinner .bounce1 {
//   -webkit-animation-delay: -0.32s;
//   animation-delay: -0.32s;
// }

// .spinner .bounce2 {
//   -webkit-animation-delay: -0.16s;
//   animation-delay: -0.16s;
// }

// @-webkit-keyframes sk-bouncedelay {
//   0%, 80%, 100% { -webkit-transform: scale(0) }
//   40% { -webkit-transform: scale(1.0) }
// }

// @keyframes sk-bouncedelay {
//   0%, 80%, 100% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   } 40% {
//     -webkit-transform: scale(1.0);
//     transform: scale(1.0);
//   }
// }
// end
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky {
  top: 66px;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
}
.timeline-calendar {
  height: 35px;
  border: none;
  text-align: left;
  // background: linear-gradient(-150deg, #1ab394, #1a91ae);
  // border-image: linear-gradient(-150deg, #1ab394, #1a91ae);
  background: #96c7c1;
  border-image: #96c7c1;
  border-image-slice: 1;
  color: black;
}
.fc-timeline-event {
  height: 35px;
  border-radius: 25px;
}

// .fc-timeline-lane-frame{
//   height: 35px !important;
// }
.fc-blocked-event {
  background: lightgray;
  height: 35px;
  text-align: left;
  border-color: lightgray;
  color: black !important;
}

.fc-room-dirty {
  background: #ed5565;
  color: #fff;
}

.fc-combo-room {
  background: gray;
  height: 35px;
  text-align: left;
  border: 1px white solid;
}

.fc-confirmed-event {
  height: 35px;
  border: none;
  text-align: left;
  // border-color: linear-gradient(-150deg, #f0ba69, #e08e31);
  // border: 1px solid linear-gradient(-150deg, #f0ba69, #e08e31);
  // background: linear-gradient(-150deg, gray, white);
  // border-image: linear-gradient(-150deg, #f0ba69, #e08e31);
  border-image: #f7d59c;
  background: #f7d59c;
  // background-image: rgb(223, 219, 219);
  border-image-slice: 1;
  color: black !important;
  padding-left: "10px !important";
}

.fc-checkedout-event {
  height: 35px;
  border: none;
  text-align: left;
  // border-color: linear-gradient(-150deg, #4ea8ec, #3084c6);
  // border: 1px solid linear-gradient(-150deg, #4ea8ec, #3084c6);
  // background: linear-gradient(-150deg, gray, white);
  // border-image: linear-gradient(-150deg, #4ea8ec, #3084c6);
  background: #a3aecc;
  border-image: #a3aecc;
  // background-image: rgb(223, 219, 219);
  border-image-slice: 1;
  color: black !important;
  padding-left: "10px";
}
.fc-cancelled-event {
  height: 35px;
  text-align: left;
  background: #fff;
  border-color: $primary;
  color: $primary;
  border-width: 2px;
}

.fc-cancelled-event::after {
  text-align: left;
  background: #fff;
  border-image: linear-gradient(-150deg, #1ab394, #1a91ae);
  border-image-slice: 1;
}

.calendar-text {
  background: linear-gradient(-150deg, #1ab394, #1a91ae);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.day-header {
  color: #2c3e50;
}

.fc-timeline-more-link {
  background: none;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.fc-theme-standard td:nth-child(3n),
.fc-theme-standard td:nth-child(3n + 2) {
  border-left-color: white;
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

// @import 'loaders.css/src/animations/ball-clip-rotate.scss';

@import "loaders.css/src/animations/ball-scale.scss";

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.fc-enquiry-event {
  height: 35px;
  border: none;
  text-align: left;
  border-color: rgb(133, 131, 131);
  border: 1px solid rgb(133, 131, 131);
  // background: linear-gradient(-150deg, gray, white);
  border-image: rgb(133, 131, 131);
  background: rgb(133, 131, 131);
  // background-image: rgb(223, 219, 219);
  border-image-slice: 1;
  color: black !important;
}

// .fc-checkedout-event::after {
//   text-align: center;
//   background: #fff;
//   border-image: blue;
//   border-image-slice: 1;
// }

// .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
//   width: 50%;
// }

// .css-1ab2xsx {
//   width: 100%;
// }

#folio-config-id {
  padding: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
